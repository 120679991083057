import React, { useEffect, useState } from "react";
import styles from "./JoinPageEdit.module.css";
import { Alert, Button, Card, Col, Form, Input, message, Row } from "antd";
import {
  getJoinPageContent,
  updateJoinPageContent,
} from "../../services/content/content.service";
import CustomEditor from "../CustomEditor";

const JoinPageEdit = () => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState<any>([]);

  const handleUpdateJoinPageContent = async (values: any) => {
    try {
      const response = await updateJoinPageContent(fields);
      if (response.success) {
        message.success("Join Page content updated successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetJoinPageContent = async () => {
    try {
      const response = await getJoinPageContent();

      if (response?.code) {
        console.log("Error");
      }
      const contents = response?.data;

      setFields(contents);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetJoinPageContent();
  }, []);

  return (
    <div className={styles.container}>
      <Alert
        type="info"
        message="You can edit the content of the Join page here. {{CurlyBrackets}} are used to color different parts of the text for the design."
      />
      <Row>
        <Col span={24}>
          <Form form={form}>
            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col span={24}>
                <Card>
                  <div className={styles.gutter}>
                    <h4>{fields[0]?.title}:</h4>
                    <Input
                      value={fields[0]?.title}
                      onChange={(e) => {
                        setFields((prev: any) => {
                          const newFields = [...prev];
                          newFields[0].title = e.target.value;
                          return newFields;
                        });
                      }}
                    />
                    <CustomEditor
                      content={fields?.[0]?.content?.[0]?.text}
                      index={0}
                      setContent={(newContent: any) => {
                        setFields((prev: any) => {
                          if (!prev?.[0]?.content?.[0]) {
                            return prev;
                          }

                          const newFields = [...prev];
                          newFields[0].content[0].text = newContent;
                          return newFields;
                        });
                      }}
                    />
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <div className={styles.gutter}>
                    <h4>{fields[1]?.title}:</h4>
                    <Input
                      value={fields[1]?.title}
                      onChange={(e) => {
                        setFields((prev: any) => {
                          const newFields = [...prev];
                          newFields[1].title = e.target.value;
                          return newFields;
                        });
                      }}
                    />
                    <CustomEditor
                      content={fields[1]?.content[0]?.text}
                      index={0}
                      setContent={(newContent: any) => {
                        setFields((prev: any) => {
                          if (!prev?.[1]?.content?.[0]) {
                            return prev;
                          }
                          const newFields = [...prev];
                          newFields[1].content[0].text = newContent;
                          return newFields;
                        });
                      }}
                    />
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <div className={styles.gutter}>
                    <h4>{fields[2]?.title}:</h4>
                    <Input
                      value={fields[2]?.title}
                      onChange={(e) => {
                        setFields((prev: any) => {
                          const newFields = [...prev];
                          newFields[2].title = e.target.value;
                          return newFields;
                        });
                      }}
                    />
                    {fields?.[2]?.content?.boxes?.map(
                      (box: any, boxIndex: number) => (
                        <div className={styles.gutter} key={boxIndex}>
                          <h4>{box.title}:</h4>
                          <Input
                            value={box.title}
                            onChange={(e) => {
                              setFields((prev: any) => {
                                if (!prev?.[2]?.content?.boxes?.[boxIndex]) {
                                  return prev;
                                }
                                const newFields = [...prev];
                                newFields[2].content.boxes[boxIndex].title =
                                  e.target.value;
                                return newFields;
                              });
                            }}
                          />
                          {box.paragraphs?.map(
                            (paragraph: any, pIndex: number) => (
                              <CustomEditor
                                key={pIndex}
                                content={paragraph.text}
                                index={pIndex}
                                setContent={(newContent: any) => {
                                  setFields((prev: any) => {
                                    if (
                                      !prev?.[2]?.content?.boxes?.[boxIndex]
                                        ?.paragraphs?.[pIndex]
                                    ) {
                                      return prev;
                                    }
                                    const newFields = [...prev];
                                    newFields[2].content.boxes[
                                      boxIndex
                                    ].paragraphs[pIndex].text = newContent;
                                    return newFields;
                                  });
                                }}
                              />
                            ),
                          )}
                        </div>
                      ),
                    )}
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <div className={styles.gutter}>
                    <h4>{fields[3]?.title}:</h4>
                    <Input
                      value={fields[3]?.title}
                      onChange={(e) => {
                        setFields((prev: any) => {
                          const newFields = [...prev];
                          newFields[3].title = e.target.value;
                          return newFields;
                        });
                      }}
                    />
                    {fields?.[3]?.content?.map(
                      (content: any, index: number) => (
                        <CustomEditor
                          key={index}
                          content={content.text}
                          index={index}
                          setContent={(newContent: any) => {
                            if (!fields?.[3]?.content?.[index]) {
                              return;
                            }
                            setFields((prev: any) => {
                              const newFields = [...prev];
                              newFields[3].content[index].text = newContent;
                              return newFields;
                            });
                          }}
                        />
                      ),
                    )}
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <div className={styles.gutter}>
                    <h4>{fields[4]?.title}:</h4>
                    <Input
                      value={fields[4]?.title}
                      onChange={(e) => {
                        setFields((prev: any) => {
                          const newFields = [...prev];
                          newFields[4].title = e.target.value;
                          return newFields;
                        });
                      }}
                    />
                    {fields[4]?.content?.map((content: any, index: number) => (
                      <CustomEditor
                        key={index}
                        content={content.text}
                        index={index}
                        setContent={(newContent: any) => {
                          setFields((prev: any) => {
                            const newFields = [...prev];
                            newFields[4].content[index].text = newContent;
                            return newFields;
                          });
                        }}
                      />
                    ))}
                  </div>
                </Card>
              </Col>
            </Row>
            <Button
              type="primary"
              onClick={() => {
                form.validateFields().then((values) => {
                  handleUpdateJoinPageContent(values);
                });
              }}
              style={{ marginTop: 20 }}
            >
              Save and Publish
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default JoinPageEdit;
